<template>
  <div style="background-color: #eef0f3">
    <a-card :bordered="false">
      <div class="top_content">
        <span class="title_text">工单进度：{{ formState.principalName || "" }}</span>
        <span>编号：{{ orderCode }}</span>
      </div>
      <a-divider />
      <a-steps progress-dot :current="current" size="small">
        <a-step v-for="(item,index) in orderProcessList" :key="index">
          <template #title>{{ item.nodeName || " " }}</template>
          <template #description>
            <p style="color: #2A90F4;font-size: 12px" v-if="item.limitDate">截止时间：{{ item.limitDate }}</p>
            <p style="font-size: 12px;color: #fff" v-else>-</p>
            <p style="color: #8b8e92;font-size: 12px">{{ item.actor || " " }}</p>
            <p style="color: #8b8e92;font-size: 12px">{{ item.atime || " " }}</p>
          </template>
        </a-step>
      </a-steps>
    </a-card>
    <a-card style="margin-top: 20px" :bordered="false">
      <AAnchor class="p-anchor">
        <a-tabs v-model="activeKey" @change="handelTabs">
          <a-tab-pane key="0" tab="详情">
          </a-tab-pane>
          <a-tab-pane key="1" tab="脚本">
          </a-tab-pane>
          <a-tab-pane key="2" tab="成片">
          </a-tab-pane>
          <a-tab-pane key="3" tab="模板">
          </a-tab-pane>
        </a-tabs>
      </AAnchor>
      <templateList
        v-if="activeKey == 0"
        :columns="columns"
        :content-list="contentList"
        :loading="loading"
        :title_name="'模板内容'"
        :list-name="'detail'"
        :is-button="'add'"
        :modal-title="'add'"
        :order_id="id"
        :status="formState.status"
        :scroll="{ y: 380,x:1000 }"
      />
      <templateList
        v-if="activeKey == 1"
        :columns="scriptColumns"
        :content-list="scriptList"
        :loading="scriptLoading"
        :title_name="'任务列表'"
        :pagination="scriptPagination"
        :is-button="'添加'"
        :modal-title="'添加脚本任务'"
        :list-name="'script'"
        :order_id="id"
        :principal-id="formState.principalId"
        :principal-name="formState.principalName"
        :status-list="statusList"
        :status="formState.status"
        @pagination-change="paginationChange"
        @loaded="loadList"
        :scroll="{ y:'calc(100vh - 300px)',x:1000 }"
      />
      <templateList
        v-if="activeKey == 2"
        :columns="productColumns"
        :content-list="scriptList"
        :loading="scriptLoading"
        :title_name="'任务列表'"
        :pagination="scriptPagination"
        :list-name="'product'"
        :order_id="id"
        :principal-id="formState.principalId"
        :principal-name="formState.principalName"
        :status-list="productStatusList"
        :status="formState.status"
        :is-button="'批量添加摄影场地'"
        :scroll="{ y:'calc(100vh - 300px)',x:1000 }"
        @pagination-change="paginationChange"
        @loaded="loadList"
      />
      <templateList
        v-if="activeKey == 3"
        :columns="templateColumns"
        :content-list="scriptList"
        :loading="scriptLoading"
        :title_name="'任务列表'"
        :pagination="scriptPagination"
        :is-button="'批量添加分组'"
        :list-name="'template'"
        :order_id="id"
        :principal-id="formState.principalId"
        :principal-name="formState.principalName"
        :status-list="templateStatusList"
        :status="formState.status"
        :scroll="{ y:'calc(100vh - 300px)',x:1000 }"
        @pagination-change="paginationChange"
        @loaded="loadList"
      />
      <div class="template_box">
        <a-button
          @click="handleButtons"
          class="mr-10"
          v-if="current == 0"
        >退回
        </a-button>
        <a-button
          @click="goBlack"
          class="mr-10"
        >返回
        </a-button>
        <a-button
          type="primary"
          @click="SubmitButtons"
          v-if="formState.status !== 9"
        >{{
            current == 0 ? "接收" : current == 1 ? "提交脚本" : current == 2 ? "完成定稿" : current == 3 ? "提交成片" : current == 4 ? "完成审核" : current == 5 ? "完成上线" : current == 6 ? "完成验收" : ""
          }}
        </a-button>
      </div>
    </a-card>
  </div>
</template>

<script>

import _api from "@/api/contentProduction.js";
import templateList from "./components/template_list";


export default {
  name: "WorkOrderManagementDetail",
  data() {
    return {
      current: 0,
      activeKey: "0",
      id: this.$route.query.id,
      orderCode: this.$route.query.orderCode,
      formState: {},
      title_name: "模板内容",
      columns: [
        {
          dataIndex: "seriesName",
          width: 140,
          title: "车系"
        },
        {
          dataIndex: "guideshootTotal",
          width: 130,
          title: "模板数量"
        },
        {
          dataIndex: "seriesDescription",
          title: "车系卖点"
        },
        {
          dataIndex: "contentType",
          title: "内容类型"
        },
      ],
      contentList: [],
      loading: false,
      orderProcessList: [],
      scriptPagination: {
        page_num: 1,
        page_size: 10,
        total: 0
      },
      scriptColumns: [
        {
          dataIndex: "scriptCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "status"},
          width: 130,
          title: "状态"
        },
        {
          dataIndex: "seriesName",
          width: 120,
          title: "车系"
        },
        {
          dataIndex: "scriptType",
          width: 100,
          title: "脚本类型"
        },
        {
          dataIndex: "directorName",
          width: 100,
          title: "编导"
        },
        {
          dataIndex: "scriptDate",
          width: 120,
          title: "脚本日期"
        },
        {
          scopedSlots: {customRender: "difficulty"},
          width: 90,
          title: "难度"
        },
        {
          scopedSlots: {customRender: "names"},
          width: 200,
          title: "脚本名称",
          ellipsis: true,
        },
        {
          dataIndex: "suggestion",
          title: "最新修改意见",
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      productColumns: [
        {
          dataIndex: "productCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "productStatus"},
          width: 130,
          title: "状态"
        },
        {
          scopedSlots: {customRender: "names"},
          width: 200,
          title: "脚本名称",
          ellipsis: true,
        },
        {
          dataIndex: "seriesName",
          width: 120,
          title: "车系"
        },
        {
          dataIndex: "productType",
          width: 100,
          title: "成片类型"
        },
        {
          dataIndex: "photographyName",
          width: 200,
          title: "摄影"
        },
        {
          dataIndex: "photoDate",
          width: 120,
          title: "拍摄日期"
        },
        {
          dataIndex: "editorName",
          width: 100,
          title: "剪辑"
        },
        {
          dataIndex: "editDate",
          width: 120,
          title: "剪辑日期"
        },
        {
          scopedSlots: {customRender: "version"},
          width: 120,
          title: "成片版本"
        },
        {
          dataIndex: "suggestion",
          title: "最新修改意见",
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      templateColumns: [
        {
          dataIndex: "guideshootCode",
          width: 80,
          title: "编号"
        },
        {
          scopedSlots: {customRender: "guideshootStatus"},
          width: 130,
          title: "状态"
        },
        {
          dataIndex: "guideshootName",
          width: 120,
          title: "模板名称",
          ellipsis: true,
        },
        {
          dataIndex: "fhlGuideshootId",
          width: 150,
          title: "模板ID"
        },
        {
          dataIndex: "uploadTotal",
          width: 100,
          title: "上传次数"
        },
        {
          dataIndex: "uploadDate",
          width: 120,
          title: "上传日期"
        },
        {
          dataIndex: "testDate",
          width: 120,
          title: "测试日期"
        },
        {
          dataIndex: "groupNames",
          width: 150,
          title: "可见分组"
        },
        {
          dataIndex: "fhlGuideshootStatus",
          scopedSlots: {customRender: "fhlGuideshootStatus"},
          width: 100,
          title: "上架状态"
        },
        {
          dataIndex: "releaseDate",
          width: 120,
          title: "完成日期"
        },
        {
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
          width: 100,
          fixed: "right",
          title: "操作"
        }
      ],
      scriptList: [],
      scriptLoading: false,
      statusList: [
        {
          color: "#ff4446",
          name: "待分配",
          id: 1
        },
        {
          color: "#2A90F4",
          name: "策划中",
          id: 2
        },
        {
          color: "#2A90F4",
          name: "内审中",
          id: 3
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 4
        },
        {
          color: "#ff4446",
          name: "待定稿",
          id: 5
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 6
        },
        {
          color: "#666",
          name: "备用/待定",
          id: 7
        },
        {
          color: "#666",
          name: "内审退回",
          id: 8
        },
        {
          color: "#666",
          name: "客审退回",
          id: 9
        },
      ],
      productStatusList: [
        {
          color: "#ff4446",
          name: "待分配",
          id: 1
        },
        {
          color: "#2A90F4",
          name: "拍摄中",
          id: 2
        },
        {
          color: "#ff4446",
          name: "待剪辑",
          id: 3
        },
        {
          color: "#2A90F4",
          name: "剪辑中",
          id: 4
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 5
        },
        {
          color: "#2A90F4",
          name: "内审中",
          id: 6
        },
        {
          color: "#ff4446",
          name: "待审核",
          id: 7
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 8
        },
        {
          color: "#666",
          name: "客审退回",
          id: 9
        },
      ],
      templateStatusList: [
        {
          color: "#ff4446",
          name: "待上传",
          id: 1
        },
        {
          color: "#ff4446",
          name: "待调整",
          id: 2
        },
        {
          color: "#2A90F4",
          name: "测试中",
          id: 3
        },
        {
          color: "#ff4446",
          name: "待上线",
          id: 4
        },
        {
          color: "#0bc9b9",
          name: "已完成",
          id: 5
        },
        {
          color: "#666",
          name: "暂不上线",
          id: 6
        },
        {
          color: "#2A90F4",
          name: "修改中",
          id: 7
        },
      ],
    };
  },
  components: {
    templateList,
  },
  mounted() {
    if (this.id) {
      this.getOrderData();
      this.getOrderProcess();
    }
  },
  methods: {
    getOrderData() {
      this.loading = true;
      _api.getOrderData(this.id).then(res => {
        if (res.code == 200) {
          this.contentList = res.data.contentList || [];
          this.formState = res.data || {};
          let status = res.data.status;
          this.current = status == 2 ? 0 : status == 3 ? 1 : status == 4 ? 2 : status == 5 ? 3 : status == 6 ? 4 : status == 7 ? 5 : status == 8 ? 6 : 6;
        } else {
          this.$message.error(res.message || "获取工单详情失败");
        }
        this.loading = false;
      });
    },// 详情 模板内容列表
    getOrderProcess() {
      _api.getOrderProcess(this.id).then(res => {
        if (res.code == 200) {
          this.orderProcessList = res.data || [];
        } else {
          this.$message.error(res.message || "获取工单进度详情失败");
        }
      });
    },// 进度详情
    handleButtons() {
      let that = this;
      this.$confirm({
        title: "确定退回工单吗？",
        content: "退回后，请与下单人说明原因，修改内容重新提交！",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.putOrderProcess({orderId: that.id, action: 1});
        },
        onCancel() {
          // console.log("取消");
        },
      });
    },// 退回
    SubmitButtons() {
      this.putOrderProcess({orderId: this.id});
    }, // 工单流转按钮
    putOrderProcess(data) {
      _api.putOrderProcess(data).then(res => {
        if (res.code == 200) {
          this.$router.go(-1);
          this.$message.success("工单流转成功");
        } else {
          this.$message.error(res.message || "工单流转失败");
        }
      });
    }, //工单流转
    handelTabs() {
      this.scriptPagination.page_num = 1;
      this.scriptPagination.page_size = 10;
      switch (this.activeKey) {
        case "0":
          this.getOrderData();
          break;
        case "1":
          this.getScriptList();
          break;
        case "2":
          this.getProductList();
          break;
        case "3":
          this.getTemplateList();
          break;
      }
    },// 切换tabs
    getProductList() {
      this.scriptLoading = true;
      let params = {
        orderId: this.id,
        page: this.scriptPagination.page_num,
        size: this.scriptPagination.page_size,
      };
      _api.getProductList(params).then(res => {
        if (res.code == 200) {
          this.scriptList = res.data.list || [];
          // this.scriptList = this.scriptList.map(item => {
          //   item.script_name = item.scriptName.split(".")[0];
          //   return item;
          // });
          this.scriptPagination.total = res.data.total || 0;
        } else {
          this.scriptList = [];
          this.scriptPagination.total = 0;
          this.$message.error(res.message || "获取成片列表失败");
        }
        this.scriptLoading = false;
      });
    }, // 成片列表
    getScriptList() {
      this.scriptLoading = true;
      let params = {
        orderId: this.id,
        page: this.scriptPagination.page_num,
        size: this.scriptPagination.page_size,
      };
      _api.getScriptList(params).then(res => {
        if (res.code == 200) {
          this.scriptList = res.data.list || [];
          // this.scriptList = this.scriptList.map(item => {
          //   item.script_name = item.scriptName.split(".")[0];
          //   return item;
          // });
          this.scriptPagination.total = res.data.total || 0;
        } else {
          this.scriptList = [];
          this.scriptPagination.total = 0;
          this.$message.error(res.message || "获取脚本列表失败");
        }
        this.scriptLoading = false;
      });
    }, // 脚本列表
    getTemplateList() {
      this.scriptLoading = true;
      let params = {
        orderId: this.id,
        page: this.scriptPagination.page_num,
        size: this.scriptPagination.page_size,
      };
      _api.getTemplateList(params).then(res => {
        if (res.code == 200) {
          this.scriptList = res.data.list || [];
          // this.scriptList = this.scriptList.map(item => {
          //   // item.scriptName = item.guideshootName;
          //   return item;
          // });
          this.scriptPagination.total = res.data.total || 0;
        } else {
          this.scriptList = [];
          this.scriptPagination.total = 0;
          this.$message.error(res.message || "获取模板列表失败");
        }
        this.scriptLoading = false;
      });
    }, // 模板列表
    paginationChange(page_num, page_size) {
      this.scriptPagination.page_num = page_num;
      this.scriptPagination.page_size = page_size;
      switch (this.activeKey) {
        case "1":
          this.getScriptList();
          break;
        case "2":
          this.getProductList();
          break;
        case "3":
          this.getTemplateList();
          break;
      }
    },   // 处理分页
    loadList(type) {
      this.scriptPagination.page_num = 1;
      this.scriptPagination.page_size = 10;
      switch (type) {
        case "script":
          this.getScriptList();
          break;
        case "product":
          this.getProductList();
          break;
        case "template":
          this.getTemplateList();
          break;
      }
    },//更新列表
    goBlack() {
      this.$router.go(-1);
    },
  }
};
</script>

<style scoped lang="less">
.gaManageTab-box {
  //height: 600px;
  min-height: 600px;
  max-height: 1200px;
  background: #fff;
}

.template_box {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
}


.mr-10 {
  margin-right: 20px;
}

.top_content {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

sticky[fixed] {
  padding-top: 90px;
}

.sticky-fixed {
  background: #fff;
}
</style>
